export default {
  landing: {
    subscribeForm: {
      title: 'Suscríbete al newsletter',
      subtitle: 'Mantente al día',
      subscribe: 'Suscribirse',
      subscribed: '¡Suscrito!',
      placeholder: 'Ingresa tu email',
      error: 'Hubo un error. Intenta de nuevo más tarde.',
    },
    demoDayCompanyList: {
      description: 'Estas son las startups que participarán en el Demo Day de Platanus.',
      subtitle: 'Conoce las',
      title: 'Startups',
      viewProfile: 'Ir al perfil',
    },
  },
  here: 'acá',
  general: {
    founders: 'Fundadores',
    founder: 'Fundador',
  },
  form: {
    loading: 'Cargando',
    yes: 'Sí',
    no: 'No',
    submit: 'Enviar',
    required: 'Obligatorio',
    confirm: 'Confirmar',
    cancel: 'Cancelar',
    file: {
      upload: 'Sube tu archivo',
      orDrag: 'o arrastralo',
      upTo: 'Hasta',
    },
    accept: 'Aceptar',
    optional: 'Opcional',
    close: 'Cerrar',
    continue: 'Continuar',
    phone: {
      validating: 'validando...',
      invalid: {
        warning: 'No pudimos validar tu número de teléfono.',
        tooltip: 'Puede que este número de teléfono no esté habilitado para recibir mensajes de ' +
                      'Whatsapp. Asegúrate de ingresar un número de Whatsapp válido.',
      },
      valid: 'Número de teléfono válido.',
    },
  },
  user: {
    form: {
      firstName: 'Nombre',
      lastName: 'Apellido',
      linkedin: 'URL de LinkedIn',
      country: 'País',
    },
    demoDayBadge: {
      angel: 'Ángel',
    },
  },
  layout: {
    navbar: {
      logOut: 'Cerrar sesión',
      editProfile: 'Editar perfil',
    },
  },
  people: {
    inactive: 'Inactiva',
    goToProfile: 'Ir a perfil',
    contact: 'Contactar',
    officeHours: 'Agendar office hour',
    emailText: 'Puedes envíar un email a',
  },
  filters: 'Filtros',
  list: {
    filters: {
      batch: 'Batch',
      company: 'Empresa',
      investedCompany: 'Empresa en la que invirtió',
      investorType: 'Tipo de inversionista',
      investmentStage: 'Etapa de inversión',
      leadRounds: 'Lidera rondas',
      investmentTicket: 'Tickets de inversión',
      country: 'País',
      rating: 'Calificación',
      industry: 'Industria',
      tag: 'Tags',
      activeStatus: 'Estado',
      kind: 'Tipo de contenido',
      category: 'Categoría',
      position: 'Puesto',
      formatOfOperation: 'Formato de Operación',
      activeStatuses: {
        true: 'Activa',
        false: 'Inactiva',
      },
    },
    search: 'Buscar...',
    cleanAllFilters: 'Limpiar filtros',
    applyFilters: 'Aplicar filtros',
    noResults: 'No encontramos lo que buscabas',
    noResultsDescription: 'Intenta con otros filtros u otra búsqueda.',
  },
  comments: {
    title: 'Comentarios',
    noComments: 'No hay comentarios.',
  },
  demoDay: {
    application: {
      basicInfo: 'Información básica',
      fund: {
        title: 'Fondo',
      },
      others: {
        title: 'Otros',
      },
      communityApplication: 'Pasos previos a invertir',
      acceptRules: 'He leído, comprendido y acepto las definiciones y reglas de participación en el Demo Day.',
      qualifiedInvestor: 'Tengo la calidad de Inversionista Calificado de conformidad con la norma de' +
                          'carácter general N° 336 de la CMF (no es requisito para participar).',
      accreditedInvestor: 'Tengo la calidad de Accredited Investor de conformidad con la Regla 501' +
                          'del Reglamento D sobre la Ley de Valores del año 1933 de EE.UU' +
                          '(no es requisito para participar).',
      rules: 'Reglas',
      success: {
        title: '¡Listo!',
        description: 'Ahora puedes invertir en las startups de este batch.',
      },
      fastApplicationDescription: 'Antes de que puedas invertir en las startups, necesitamos ' +
                                    'preguntarte un par de cosas y que aceptes las reglas del DemoDay.',
      applicationStep: 'Información básica',
      acceptRulesStep: 'Aceptar las reglas',
      regions: {
        asia: 'Asia',
        europe: 'Europa',
        africa: 'Africa',
        usaCanada: 'USA y Canada',
        latam: 'Latinoamérica',
        oceania: 'Oceanía',
      },
    },
    company: {
      schedule: 'Agendar',
      keyPoints: 'Puntos clave',
      like: 'Me gusta',
      liked: 'Ok, te gusta',
      pitch: 'Pitch',
    },
    rules: {
      toggle: 'Ver reglas completas',
    },
    streaming: {
      attendees: 'Asistentes',
      connections: 'Conexiones',
      fullscreen: 'Pantalla completa',
    },
    virtualRound: {
      title: 'Ronda virtual',
      invest: 'Invertir',
      scheduleOrConnect: 'Agendar o conectar',
      scheduleMeeting: 'Agendar reunión 📆',
      connectWithFounders: 'Conectar por email 📨',
      connectExplanation: `Te enviaremos un mail a ti y a los founders para que puedan seguir la
        conversación por ahí.`,
      roundInfo: 'Información de la ronda',
      companyInfo: 'Sobre la startup',
      batch: 'Generación {season}',
      someoneInvested: '🤑 Alguien acaba de invertir.',
      someoneScheduledMeeting: '📅 Alguien agendó una reunión.',
      someoneConnected: '📨 Alguien conectó con una startup.',
      investIn: 'Invertir en',
      auctionOfferInput: 'Ingresa tu oferta (en USD)',
      improveAuctionOfferInput: 'Ingresa el monto de tu nueva oferta (USD)',
      yourOffer: 'Tu oferta',
      yourOfferDetails: 'Detalles de tu oferta',
      existingOfferDetails: 'Detalles de tu oferta anterior',
      willMakeOffer: 'Ingresarás una oferta con las siguientes características',
      everythingCorrect: '¿Está todo correcto?',
      yourLastOffer: 'Tu última oferta',
      selectSchedulingMotive: 'Selecciona el motivo de tu interés',
      otherMotive: 'Otro motivo',
      schedulingMotives: {
        invest: 'Para invertir',
        client: 'Para ser cliente',
        partnership: 'Para partnerships',
        other: 'Para otra cosa',
      },
      orderCreatedSuccess: {
        title: 'Tu oferta fue ingresada con éxito.',
        body: `Una vez finalizada la Ronda Virtual, y si es que tu oferta es seleccionada,
        la startup te escribirá a tu correo para los siguientes pasos.

        Por ahora, puedes completar tu información de inversión.`,
        accept: 'Completar ahora',
        close: 'Más tarde',
      },
      auction: {
        size: 'Tamaño ronda',
        sizeTooltip: `El tamaño de la ronda es la suma del monto levantado en las semanas previas al Demo Day y el
                      monto que la startup está buscando levantar durante el Demo Day.`,
        timeLeft: 'Termina en',
        startsIn: 'Empieza en',
        hasNotStarted: 'La ronda aún no ha comenzado.',
        days: 'D',
        hours: 'H',
        makeOffer: 'Ingresar oferta',
        improveOffer: 'Mejorar oferta',
        confirmOffer: 'Sí, ingresar oferta',
        completelyIn: 'Tu oferta está siendo <b>SELECCIONADA</b>.',
        partiallyIn: 'Tu oferta está siendo <b>PARCIALMENTE</b> seleccionada.',
        out: 'Tu oferta <b>NO</b> está siendo seleccionada.',
        wantToKnowWhy: 'Si quieres saber por qué, puedes preguntarnos en el chat.',
        rememberToFillLegalInformation: 'Recuerda llenar tus datos legales para la inversión. Puedes hacerlo',
        uncapped: 'Sin cap',
        minOrderAmount: 'El ticket mínimo es de US {amount}.',
        finished: 'La ronda terminó.',
        previouslyRaised: 'Levantado pre Demo Day',
        virtualRoundAmount: 'Monto Ronda Virtual',
        minAmount: 'Ticket mínimo',
      },
      goToStreaming: 'Ir al streaming',
    },
    investmentLegalInformation: {
      investmentData: 'Datos para la inversión',
      selectAnyTwoOptions: 'Selecciona alguna de las dos opciones',
      investmentType: {
        legalPerson: 'Persona legal',
        naturalPerson: 'Persona natural',
      },
      previousInformation: 'Información anterior',
      addNewInformation: 'Agregar nueva información',
      form: {
        personType: {
          legal: 'Jurídica',
          natural: 'Natural',
        },
        legal: {
          businessName: 'Razón social o nombre legal de la entidad',
          legalPersonRut: 'RUT o Tax ID de la entidad legal',
          legalRepresentativeRut: 'RUN o número de identificación de representante legal',
          legalRepresentativeName: 'Nombre completo representante legal',
          legalRepresentativeEmail: 'Correo electrónico representante legal',
          legalPersonAddress: 'Dirección de la entidad legal',
        },
        natural: {
          naturalPersonName: 'Nombre completo',
          naturalPersonRut: 'RUN o número de identificación',
          naturalPersonAddress: 'Dirección',
        },
      },
    },
    presentationsFinished: {
      message: 'Las presentaciones terminaron.',
      goToRewatch: 'Puedes ver las grabaciones de cada startup acá.',
    },
    connections: {
      connect: 'Conectar',
      connectNotification: '¡Conectado con %{companyName}! Revisa tu correo.',
      alreadyConnected: 'Ya conectado',
      investorForm: {
        title: 'Ingresa tus datos de inversionista',
        investorName: 'Nombre completo',
        investorCompany: 'Empresa',
        investorCompanyDescription: 'Deja en blanco si eres un inversionista angel.',
        investorPhone: 'Teléfono',
        submit: 'Ir a Platanus Connect',
      },
    },
  },
  applicationProcess: {
    apply: 'Postular',
    programDates: {
      earlyApplicationProcess: {
        title: 'Postulación temprana',
        description: `Apertura: <strong>{startDate}</strong>.<br>
                      Fecha límite de postulación: <strong>{endDate}</strong> de <strong>Chile</strong>.<br><br>
                      Recuerda que si postulas en el periodo de postulación temprana, te entrevistaremos antes
                      y te tendremos más tiempo en mente.`,
      },
      generalApplicationProcess: {
        title: 'Postulación general',
        description: 'La fecha límite de postulación general es el <strong>{date}</strong> de <strong>Chile</strong>.',
      },
      lateApplicationProcess: {
        title: 'Postulación tardía',
        description: `La última fecha para postular es el <strong>{date}</strong> de Chile. Si aplicas en este
                      periodo no podemos garantizar una respuesta a tu postulación.`,
      },
      results: {
        title: 'Resultados',
        description: 'La fecha en la que sabrás si avanzas en el proceso depende del periodo en que postules.',
        early: 'Postulación temprana: a más tardar el {date}',
        general: 'Postulación general: a más tardar el {date}',
        late: 'Postulación tardía: a más tardar el {date}',
      },
      program: {
        title: 'Programa',
        description: `El programa dura 3 meses, entre <strong>{startDate}</strong> y
                      <strong>{endDate}</strong>.`,
      },
    },
  },
  startupApplication: {
    status: {
      states: {
        submitted: 'En revisión',
        toReview: 'En revisión',
        inReview: 'En revisión',
        inShortInterview: 'Entrevista corta',
        inLongInterview: 'Entrevista larga',
        inPersonInterview: 'Entrevista presencial',
        waitingResults: 'Esperando resultados',
        selected: 'Seleccionada',
        discarded: 'Cerrada',
        inLimbo: 'En revisión',
      },
    },
    aasmStates: {
      submitted: 'Enviada',
      toReview: 'Por revisar',
      inReview: 'En revisión',
      inShortInterview: 'Entrevista corta',
      inLongInterview: 'Entrevista larga',
      inReferenceCheck: 'Chequeo de referencias',
      inPersonInterview: 'Entrevista presencial',
      waiting: 'Esperando resultados',
      selected: 'Seleccionada',
      discarded: 'Descartada',
      inLimbo: 'En limbo',
    },
    qualifications: {
      reviewed: 'Revisada',
      'no_chance': 'No tiene chance',
      'low_chance': 'Baja chance',
      maybe: 'Quizás',
      candidate: 'Candidata',
    },
    review: {
      founders: 'founder | founders',
      filters: {
        search: 'Busqueda',
        applicationProcessId: 'Proceso de postulación',
        hasFemaleFounder: 'Con fundadora mujer',
        hasTechnicalFounder: 'Con fundador técnico',
        aasmState: 'Estado',
        country: 'País',
        qualification: 'Calificación',
        reviewerId: 'Encargado',
        discardEmailState: 'Estado mail de descarte',
        tags: 'Tags',
        industries: 'Industrias',
        applicationPeriods: 'Períodos de postulación',
        educationInstitution: 'Institución educacional',
        previousCompany: 'Empresa previa',
        revenueToWorkingInIdeaMonths: 'Último revenue / meses inicio mínimo',
        revenueToFullTimeMonths: 'Último revenue / meses full time mínimo',
        referred: 'Referida',
        appliedMoreThanOnce: 'Postuló antes',
        reachedInterview: 'Llegó a entrevista',
      },
    },
    shortDescription: 'Descripción corta de tu startup (50 caracteres)',
    longDescription: '¿Qué hace o va a hacer tu startup?',
  },
  companyStatus: {
    sections: {
      weeklyReports: 'Reportes semanales',
      groupMeetings: 'Coliseos',
      investorUpdates: 'Updates a inversionistas',
    },
    investorUpdate: {
      rowTitle: 'Actualización {date}',
      body: 'Mensaje',
      metricLabel: 'Métrica',
      metric: 'Valor',
      asks: 'Asks (peticiones a los inversionistas)',
      new: 'Nueva actualización',
      createSuccess: 'Actualización creada!',
      addMetric: 'Agregar KPI',
      otherMetricLabel: 'Nombre',
    },
  },
  actions: {
    show: 'Show',
    edit: 'Edit',
    applications: 'Applications',
    cancel: 'Cancel',
    send: 'Send',
    delete: 'Delete',
  },
  libraryItems: {
    listTitle: 'Biblioteca de Platanus',
    listSubtitle: 'Acá puedes encontrar el conocimiento que hemos ido recopilando y generando a lo largo del tiempo.',
    copyLink: 'Copiar Link',
  },
  officeHours: {
    copyLink: 'Copiar link',
  },
  jobOffers: {
    listTitle: {
      default: 'Ofertas de trabajos',
      owner: 'Tus ofertas de trabajos',
    },
    listSubtitle: {
      default: 'Estas son las posiciones abiertas de las startups de Platanus.',
      owner: 'Estas son tus posiciones abiertas',
    },
    applications: '{count} postulaciones | {count} postulación | {count} postulaciones',
    new: 'Agregar oferta',
    newTitle: 'Agregar nueva oferta',
    editTitle: 'Editar oferta',
    newSubmit: 'Crear oferta',
    editSubmit: 'Guardar oferta',
    active: 'Activa',
    wageRange: 'Rango de sueldo',
    countryInformation: 'País',
    formatOfOperation: 'Formato de Operación',
    position: 'Nombre de la posición',
    aboutUs: 'Sobre nosotros',
    benefits: 'Beneficios',
    description: 'Sobre el trabajo',
    requiredSkills: 'Habilidades requeridas',
    otherOffers: 'Otras ofertas de {companyName}',
    apply: 'Postular',
    applied: 'Postulaste',
    closed: 'Oferta Cerrada',
    formatOfOperations: {
      presential: 'Presencial',
      remote: 'Remoto',
      hybrid: 'Híbrido',
    },
    deleteModal: {
      confirmationMessage: '¿Estas seguro que quieres eliminar esta Oferta de trabajo?',
      explanationMessage: `Al confirmar la eliminación de esta Oferta de trabajo se eliminará
      complemente y no podrás volver a acceder a esta información de nuevo.`,
      confirmationAction: 'Sí, estoy seguro',
    },
    actions: {
      show: 'Ver',
      edit: 'Editar',
      applications: 'Postulantes',
      cancel: 'Cancelar',
      send: 'Enviar',
      delete: 'Eliminar',
    },
    application: {
      title: 'Postulación',
      resume: 'Currículum',
      aboutYou: 'Cuéntanos un poco sobre ti',
      successfullySent: 'Tu postulación fue enviada exitosamente',
    },
  },
  jobApplications: {
    titles: {
      default: 'Postulaciones',
      owner: 'Postulaciones a tus ofertas de trabajo',
      withPosition: 'Postulación a %{position}',
    },
    subTitles: {
      owner: 'Estas son las postulaciones que has recibido',
    },
    name: 'Nombre',
    email: 'Email',
    purpose: 'Motivación',
    actions: {
      show: 'Ver',
      downloadCV: 'Descargar CV',
    },
  },
  perks: {
    suggestions: {
      do: 'Sugerir perk',
      notFoundQuestion: '¿No enconstraste el perk que necesitas?',
      successfullySent: 'El perk sugerido fue enviado correctamente.',
      nameReference: "Nombre de referencia (Ej: 'Descuentos en Heroku dynos')",
      description: 'Describe en detalle el perk',
    },
    showBenefits: 'Ver Beneficios',
    hideBenefits: 'Ocultar Beneficios',
    benefits: 'Beneficios',
    use: 'Canjear perk',
    redeem: 'Canjear este perk - %{companyName}',
    redeemInstructions: 'Sigue las siguientes instrucciones para canjear este perk:',
    fileUrl: 'Archivo asociado:',
    download: 'Descargar',
  },
  investors: {
    listTitle: 'Inversionistas',
    kind: {
      vcFund: 'Firma de Venture Capital',
      familyOffice: 'Family Office',
      angel: 'Inversionista Ángel',
      accelerator: 'Aceleradora',
      cvc: 'CVC',
      syndicate: 'Syndicate',
    },
    stages: {
      preSeed: 'Pre-seed',
      seed: 'Seed',
      seriesA: 'Series A',
      seriesB: 'Series B',
      seriesC: 'Series C',
      beyondSeriesC: 'Series D+',
    },
    investments: 'inversiones',
    calification: 'Calificación',
    noCalification: 'Sin calificación',
    ticketsBetween: 'Tickets entre %{min} y %{max}',
    averageTicket: 'Ticket promedio %{averageTicket}',
    leadRounds: 'Lidera rondas',
    doNotLeadRounds: 'No lidera rondas',
  },
  genericError: 'Hubo un error. Intenta de nuevo más tarde.',
  calendar: {
    upcomingEvents: 'Próximos eventos',
    subscribe: 'Añadir a mi calendario',
    noEvents: 'No se encontraron eventos...',
    showMore: 'Ver más',
  },
  tracking: {
    portfolioValuations: {
      stages: {
        preSeed: 'Pre Seed',
        seed: 'Seed',
        seriesA: 'Serie A',
        seriesB: 'Serie B',
        seriesC: 'Serie C',
      },
    },
  },
  subscribers: {
    modal: {
      title: 'Suscríbete a Platanus',
      blogUpdates: 'Recibir actualizaciones del blog de Platanus',
      applyUpdates: 'Recibir actualizaciones del comienzo de las postulaciones',
      button: 'Suscribirse',
      success: '¡Suscripción actualizada! 🎉',
    },
  },
};
