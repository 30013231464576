import { ref, computed } from 'vue';
import { defineStore } from 'pinia';

const initialFilters = {
  demoDayId: null,
  aasmState: null,
  kind: null,
  country: null,
};

function getFiltersFromQueryParams() {
  const searchParams = new URLSearchParams(window.location.search);
  const filters = {};

  Object.keys(initialFilters).forEach((key) => {
    const value = searchParams.get(key);
    filters[key] = value || initialFilters[key];
  });

  return filters;
}

const useInvestorApplicationFiltersStore = defineStore('investorApplicationFilters', () => {
  const filters = ref(getFiltersFromQueryParams());

  const appliedFilters = computed(() => {
    const _filters = {};
    const values = filters.value;

    if (values.demoDayId) _filters.demoDayId = Number(values.demoDayId);
    if (values.aasmState) _filters.aasmState = values.aasmState;
    if (values.kind) _filters.kind = values.kind;
    if (values.country) _filters.country = values.country;

    return _filters;
  });

  function addFilters(values) {
    filters.value = { ...filters.value, ...values };
  }

  return {
    filters,
    appliedFilters,
    initialFilters,
    addFilters,
  };
});

export { useInvestorApplicationFiltersStore as default };
