import { ref, computed } from 'vue';
import { defineStore } from 'pinia';

const useInvestorApplicationStore = defineStore('investorApplication', () => {
  const investorApplications = ref([]);
  const currentInvestorApplicationIndex = ref(0);
  const currentInvestorApplication = computed(
    () => investorApplications.value[currentInvestorApplicationIndex.value],
  );
  const currentInvestorApplicationId = computed(() => currentInvestorApplication.value?.id);

  function setInvestorApplications(value) {
    const previousApplication = currentInvestorApplication.value;
    investorApplications.value = value;

    if (previousApplication) {
      const newInvestorApplicationIndex = investorApplications.value.findIndex(
        (application) => application.id === previousApplication.id,
      );

      if (newInvestorApplicationIndex >= 0) {
        currentInvestorApplicationIndex.value = newInvestorApplicationIndex;
        investorApplications.value[newInvestorApplicationIndex] = previousApplication;
      } else {
        currentInvestorApplicationIndex.value = 0;
      }
    }
  }

  function updateCurrentInvestorApplication(value) {
    investorApplications.value[currentInvestorApplicationIndex.value] = value;
  }

  function nextInvestorApplication() {
    const nextApplication = investorApplications.value[currentInvestorApplicationIndex.value + 1];
    if (nextApplication) {
      currentInvestorApplicationIndex.value += 1;
    }
  }
  function previousInvestorApplication() {
    const previousApplication = investorApplications.value[currentInvestorApplicationIndex.value - 1];
    if (previousApplication) {
      currentInvestorApplicationIndex.value -= 1;
    }
  }
  function goToInvestorApplication(id) {
    const index = investorApplications.value.findIndex((application) => application.id === id);
    if (index >= 0) {
      currentInvestorApplicationIndex.value = index;
      investorApplications.value[index] = { ...investorApplications.value[index] };
    }
  }

  return {
    investorApplications,
    setInvestorApplications,
    updateCurrentInvestorApplication,
    previousInvestorApplication,
    nextInvestorApplication,
    goToInvestorApplication,
    currentInvestorApplicationId,
    currentInvestorApplication,
    currentInvestorApplicationIndex,
  };
});

export { useInvestorApplicationStore as default };
