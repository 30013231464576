import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "flex h-fit w-full flex-col items-center justify-center gap-4 rounded border border-slate-700 p-4 lg:h-40 lg:flex-row" };
const _hoisted_2 = ["src", "alt"];
const _hoisted_3 = { class: "flex h-full grow flex-col justify-around" };
const _hoisted_4 = { class: "text-lg text-white" };
const _hoisted_5 = { class: "grow text-pv-gray" };
const _hoisted_6 = { class: "mt-2 flex justify-between lg:mt-0" };
const _hoisted_7 = { class: "flex flex-col gap-1 text-sm transition lg:flex-row lg:gap-3" };
const _hoisted_8 = { class: "text-blue-gray-300" };
const _hoisted_9 = { class: "text-blue-gray-300" };
const _hoisted_10 = ["href"];
const _hoisted_11 = ["href"];
import { formatDistance } from 'date-fns';
import { es } from 'date-fns/locale';
import UpvotesCounter from './upvotes-counter.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'launch-show-card',
    props: {
        launch: {},
        isUpvoted: { type: Boolean }
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("img", {
                    class: "size-32 rounded-lg",
                    src: props.launch.logoUrl,
                    alt: `${props.launch.companyName} logo`
                }, null, 8, _hoisted_2),
                _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("p", _hoisted_4, _toDisplayString(props.launch.title), 1),
                    _createElementVNode("p", _hoisted_5, _toDisplayString(props.launch.tagline), 1),
                    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "my-2 h-px w-full bg-blue-gray-800 lg:hidden" }, null, -1)),
                    _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("div", _hoisted_7, [
                            _createElementVNode("p", _hoisted_8, " Hace " + _toDisplayString(_unref(formatDistance)(props.launch.createdAt, new Date(), { locale: _unref(es) })), 1),
                            _createElementVNode("p", _hoisted_9, _toDisplayString(props.launch.batchSlug), 1),
                            _createElementVNode("a", {
                                class: "grow text-pv-yellow transition hover:text-white",
                                href: props.launch.companyUrl,
                                target: "_blank"
                            }, _toDisplayString(props.launch.companyName), 9, _hoisted_10),
                            _createElementVNode("a", {
                                class: "transition hover:text-pv-yellow lg:grow lg:text-end",
                                href: props.launch.url,
                                target: "_blank"
                            }, _toDisplayString(props.launch.url), 9, _hoisted_11)
                        ]),
                        _createVNode(UpvotesCounter, {
                            class: "lg:hidden",
                            "launch-id": props.launch.id,
                            "upvotes-count": props.launch.upvotesCount || 0,
                            "is-upvoted": props.isUpvoted
                        }, null, 8, ["launch-id", "upvotes-count", "is-upvoted"])
                    ])
                ]),
                _createVNode(UpvotesCounter, {
                    class: "hidden lg:block",
                    "launch-id": props.launch.id,
                    "upvotes-count": props.launch.upvotesCount || 0,
                    "is-upvoted": props.isUpvoted
                }, null, 8, ["launch-id", "upvotes-count", "is-upvoted"])
            ]));
        };
    }
});
