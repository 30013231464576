import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "relative flex h-fit w-full flex-row items-center justify-center gap-0 rounded border border-slate-700 p-4 transition hover:bg-slate-800 hover:shadow-xl lg:h-40 lg:gap-4" };
const _hoisted_2 = ["href"];
const _hoisted_3 = ["src", "alt"];
const _hoisted_4 = { class: "flex size-full grow flex-col justify-around gap-2 lg:gap-0" };
const _hoisted_5 = { class: "text-lg text-white" };
const _hoisted_6 = { class: "grow text-sm text-pv-gray lg:text-base" };
const _hoisted_7 = { class: "flex gap-3 text-sm transition" };
const _hoisted_8 = { class: "text-blue-gray-300" };
const _hoisted_9 = { class: "text-blue-gray-300" };
const _hoisted_10 = ["href"];
const _hoisted_11 = ["href"];
import { formatDistanceToNowStrict } from 'date-fns';
import { es } from 'date-fns/locale';
import UpvotesCounter from './upvotes-counter.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'launch-index-card',
    props: {
        launch: {},
        isUpvoted: { type: Boolean }
    },
    setup(__props) {
        const props = __props;
        const PREFIX = process.env.RAILS_ENV === 'production' ? '' : '/launch-pack';
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("a", {
                    class: "absolute left-0 top-0 size-full",
                    href: `${_unref(PREFIX)}/launches/${props.launch.slug}`
                }, null, 8, _hoisted_2),
                _createElementVNode("img", {
                    class: "hidden size-32 rounded-lg lg:block",
                    src: props.launch.logoUrl,
                    alt: `${props.launch.companyName} logo`
                }, null, 8, _hoisted_3),
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("p", _hoisted_5, _toDisplayString(props.launch.title), 1),
                    _createElementVNode("p", _hoisted_6, _toDisplayString(props.launch.tagline), 1),
                    _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("p", _hoisted_8, " Hace " + _toDisplayString(_unref(formatDistanceToNowStrict)(props.launch.createdAt, { locale: _unref(es) })), 1),
                        _createElementVNode("p", _hoisted_9, _toDisplayString(props.launch.batchSlug), 1),
                        _createElementVNode("a", {
                            class: "z-20 grow text-pv-yellow transition hover:text-white",
                            href: props.launch.companyUrl,
                            target: "_blank"
                        }, _toDisplayString(props.launch.companyName), 9, _hoisted_10),
                        _createElementVNode("a", {
                            class: "z-20 hidden transition hover:text-pv-yellow lg:block lg:grow lg:text-end",
                            href: props.launch.url,
                            target: "_blank"
                        }, _toDisplayString(props.launch.url), 9, _hoisted_11)
                    ])
                ]),
                _createVNode(UpvotesCounter, {
                    class: "z-20",
                    "launch-id": props.launch.id,
                    "upvotes-count": props.launch.upvotesCount || 0,
                    "is-upvoted": props.isUpvoted,
                    "text-classes": "text-xl lg:text-4xl font-bold"
                }, null, 8, ["launch-id", "upvotes-count", "is-upvoted"])
            ]));
        };
    }
});
