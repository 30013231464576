import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { ref } from 'vue';
import { useInitialUpvoteStaticQuery, useCreateUpvoteMutation, useDeleteUpvoteMutation } from '@/launch_pack/queries/upvotes';
export default /*@__PURE__*/ _defineComponent({
    __name: 'upvotes-counter',
    props: {
        launchId: {},
        upvotesCount: {},
        isUpvoted: { type: Boolean },
        textClasses: { default: 'text-4xl font-bold' }
    },
    setup(__props) {
        const props = __props;
        const isUpvoted = ref(props.isUpvoted);
        const { data: upvotesCount } = useInitialUpvoteStaticQuery(props.launchId, props.upvotesCount);
        const { mutate: createUpvote } = useCreateUpvoteMutation(props.launchId);
        const { mutate: deleteUpvote } = useDeleteUpvoteMutation(props.launchId);
        function onClick() {
            if (isUpvoted.value) {
                deleteUpvote();
                isUpvoted.value = false;
            }
            else {
                createUpvote();
                isUpvoted.value = true;
            }
        }
        return (_ctx, _cache) => {
            const _component_inline_svg = _resolveComponent("inline-svg");
            return (_openBlock(), _createElementBlock("button", {
                class: _normalizeClass(["mx-4 mb-3 flex flex-col items-center justify-center transition", isUpvoted.value ? 'text-pv-yellow hover:text-yellow-300' : 'text-white hover:text-pv-yellow']),
                onClick: onClick
            }, [
                _createVNode(_component_inline_svg, {
                    class: "-mb-2 size-8 stroke-current",
                    src: require('assets/images/icons/chevron-up.svg')
                }, null, 8, ["src"]),
                _createElementVNode("p", {
                    class: _normalizeClass(_ctx.textClasses)
                }, _toDisplayString(_unref(upvotesCount)), 3)
            ], 2));
        };
    }
});
